import http, {getToken} from "@/http-common";
import {RideRequest} from "@/model/RideRequest";

const path = "/ride-requests"

export async function getAllRideRequests() {
    try {
        const res = await http.get(path, {
            headers: {
                'Authorization': await getToken()
            }
        });
        return res.data;
    } catch (err) {
        console.log("Error loading ride requests");
    }
    return [];
}

export async function createRideRequest(rideRequest: RideRequest): Promise<any> {
    return await http.post(path, rideRequest, {
        method: 'POST',
    }).catch(function (error) {
        return error.response
    });
}
