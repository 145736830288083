import { Auth0VueClientOptions, createAuth0 } from "@auth0/auth0-vue";
import {Config, getConfig} from "@/config";

let auth0: ReturnType<typeof createAuth0> | null = null;

export async function createAuth0Instance(): Promise<ReturnType<typeof createAuth0>> {
    const config: Config = await getConfig()
    const clientOptions: Auth0VueClientOptions = {
        domain: config.auth0Domain,
        clientId: config.auth0ClientId,
        useRefreshTokens: true,
        cacheLocation: 'localstorage',
        authorizationParams: {
            redirect_uri: config.auth0RedirectUri,
            audience: config.auth0Audience
        }
    };

    // Create or update the auth0 instance
    auth0 = createAuth0(clientOptions as Auth0VueClientOptions);
    return auth0
}

export function getAuth0Instance(): ReturnType<typeof createAuth0> {
    if (auth0 === null) {
        throw new Error('auth0 instance is not initialized. Call createAuth0Instance first.');
    }
    return auth0;
}
