import {createApp, unref} from 'vue'
import App from './App.vue'
import {createRouter, createWebHashHistory} from "vue-router";
import HomePage from './components/HomePage.vue';
import ConditionSetListPage from "@/components/ConditionSetListPage.vue";
import ConditionSetPage from "@/components/ConditionSetPage.vue";
import DriverListPage from "@/components/DriverListPage.vue";
import DriverPage from "@/components/DriverPage.vue";
import RideListPage from "@/components/RideListPage.vue";
import RidePage from "@/components/RidePage.vue";
import CallbackPage from "@/components/CallbackPage.vue";
import ImportRidesPage from "@/components/ImportRidesPage.vue";
import { store, key } from './store'
import {createAuth0Instance} from "@/auth0";
import {createConfig} from "@/config";
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import RideRequestListPage from './components/RideRequestListPage.vue';

const config = await createConfig()

const routes = [
    {path: '/', component: HomePage},
    {name: 'callback', path: '/callback', component: CallbackPage},
    {name: 'conditionSets', path: '/conditionSets', component: ConditionSetListPage},
    {name: 'conditionSet', path: '/conditionSet/:id', component: ConditionSetPage, props: true},
    {path: '/ride-requests', component: RideRequestListPage},
    {path: '/drivers', component: DriverListPage},
    {name: 'driver', path: '/driver/:id', component: DriverPage, props: true},
    {name: 'driverProfile', path: '/driverProfile', component: DriverPage},
    {path: '/import', component: ImportRidesPage},
    {name: 'rides', path: '/rides', component: RideListPage},
    {name: 'ride', path: '/ride/:id', component: RidePage, props: true},
]
const router = createRouter({
    history: createWebHashHistory(),
    routes: routes,
    linkActiveClass: "active"
})

router.beforeEach(async (to, from, next) => {
    if (to.path === "/" || to.path === "/callback")
        return next();
    await new Promise((resolve) => {
        const timer = setInterval(() => {
            if (!auth0.isLoading.value) {
                clearInterval(timer);
                resolve(null);
            }
        }, 100);
    });
    const isAuthenticated = auth0.isAuthenticated.value;
    const user = unref(auth0.user);
    if (isAuthenticated && user && user[config.auth0RolesClaim]) {
        return next();
    } else {
        return next("/");
    }
})


// Get the auth0 instance
const auth0 = await createAuth0Instance();
const app = createApp(App)

app.config.globalProperties.$filters = {
    formatDate(date : string) : string {
        return new Date(date).toLocaleDateString('en-US', {timeZone: "America/New_York"})
    },
    formatCurrency(amount: number, currency: string) : string {
        const currencyFormatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 2
        });
        return currencyFormatter.format(amount)
    }
}
app.use(router).use(auth0).use(store, key).mount('#app')
