import {CONDITION_SET_ACTION} from "@/model/acceptRide/constants/ConditionSetAction";
import {AirlineCondition} from "@/model/acceptRide/conditions/AirlineCondition";
import {v4 as UUID_v4} from "uuid";
import {RelativeStartTimeCondition} from "@/model/acceptRide/conditions/RelativeStartTimeCondition";
import {PickupCondition} from "@/model/acceptRide/conditions/PickupCondition";
import {DropOffCondition} from "@/model/acceptRide/conditions/DropOffCondition";
import {BookingTypeCondition} from "@/model/acceptRide/conditions/BookingTypeCondition";
import {DayCondition} from "@/model/acceptRide/conditions/DayCondition";
import {ServiceClassCondition} from "@/model/acceptRide/conditions/ServiceClassCondition";
import {StartDateCondition} from "@/model/acceptRide/conditions/StartDateCondition";
import {StartTimeCondition} from "@/model/acceptRide/conditions/StartTimeCondition";
import {DistanceCondition} from "@/model/acceptRide/conditions/DistanceCondition";
import {EndTimeCondition} from "@/model/acceptRide/conditions/EndTimeCondition";

export class ConditionSet {
    constructor(businessId: string, version: number, description: string, priority: number, count: number,
                action: CONDITION_SET_ACTION, enabled: boolean, airlineConditions: AirlineCondition[],
                bookingTypeConditions: BookingTypeCondition[], dayConditions: DayCondition[],
                distanceConditions: DistanceCondition[], dropOffConditions: DropOffCondition[],
                endTimeConditions: EndTimeCondition[], pickupConditions: PickupCondition[],
                relativeStartTimeConditions: RelativeStartTimeCondition[],
                serviceClassConditions: ServiceClassCondition[], startDateConditions: StartDateCondition[],
                startTimeConditions: StartTimeCondition[]) {
        this.businessId = businessId
        this.version = version
        this.description = description
        this.priority = priority
        this.count = count
        this.action = action
        this.enabled = enabled
        this.airlineConditions = airlineConditions
        this.bookingTypeConditions = bookingTypeConditions
        this.dayConditions = dayConditions
        this.distanceConditions = distanceConditions
        this.dropOffConditions = dropOffConditions
        this.endTimeConditions = endTimeConditions
        this.pickupConditions = pickupConditions
        this.relativeStartTimeConditions = relativeStartTimeConditions
        this.serviceClassConditions = serviceClassConditions
        this.startDateConditions = startDateConditions
        this.startTimeConditions = startTimeConditions
    }

    businessId: string
    version: number
    description: string
    priority: number
    count: number
    action: CONDITION_SET_ACTION
    enabled: boolean
    airlineConditions: AirlineCondition[]
    bookingTypeConditions: BookingTypeCondition[]
    dayConditions: DayCondition[]
    distanceConditions: DistanceCondition[]
    dropOffConditions: DropOffCondition[]
    endTimeConditions: EndTimeCondition[]
    pickupConditions: PickupCondition[]
    relativeStartTimeConditions: RelativeStartTimeCondition[]
    serviceClassConditions: ServiceClassCondition[]
    startDateConditions: StartDateCondition[]
    startTimeConditions: StartTimeCondition[]
}

export function getNewConditionSet(): ConditionSet {
    return new ConditionSet(UUID_v4(), 1,'', 1, 1, CONDITION_SET_ACTION.ACCEPT, true,
        [], [], [], [], [],
        [], [], [], [], [], [])
}
