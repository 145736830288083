import {v4 as UUID_v4} from "uuid";

export class DistanceCondition {
    constructor(businessId: string, minimum: number, maximum: number) {
        this.businessId = businessId
        this.minimum = minimum
        this.maximum = maximum
    }

    businessId: string
    minimum: number
    maximum: number
}

export function getNewDistanceCondition() {
    return new DistanceCondition(UUID_v4(), 0, 0)
}
