import {v4 as UUID_v4} from "uuid";
import moment from "moment-timezone";
import {DATE_TIME_FORMAT, ISO_FORMAT, NEW_YORK_ZONE, UTC_ZONE} from "@/model/acceptRide/constants/DateTimeConstants";

export class StartDateCondition {
    constructor(businessId: string, startDate: String, endDate: String) {
        this.businessId = businessId
        this.startDate = startDate
        this.endDate = endDate
    }

    businessId: string
    startDate: String
    endDate: String
}

export function getNewStartDateCondition() {
    return new StartDateCondition(UUID_v4(),
        moment.tz(UTC_ZONE).format(ISO_FORMAT),
        moment.tz(UTC_ZONE).format(ISO_FORMAT)
    )
}
