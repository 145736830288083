import {v4 as UUID_v4} from "uuid";
export enum NotificationType {
    ERROR, SUCCESS
}
export class Notification {
    constructor(id: string, content: string, type: NotificationType) {
        this.id = id
        this.content = content
        this.show = false
        this.type = type
    }
    id: string
    content: string
    show: boolean
    type : NotificationType
}

export function getNewNotification(content: string, type: NotificationType): Notification {
    return new Notification(UUID_v4(), content, type)
}
