import {v4 as UUID_v4} from "uuid";
import moment from "moment-timezone";
import {TIME_FORMAT, UTC_ZONE} from "@/model/acceptRide/constants/DateTimeConstants";

export class StartTimeCondition {
    constructor(businessId: string, startTime: string, endTime: string) {
        this.businessId = businessId
        this.startTime = startTime
        this.endTime = endTime
    }

    businessId: string
    startTime: string
    endTime: string
}

export function getNewStartTimeCondition() {
    return new StartTimeCondition(UUID_v4(), moment.tz(UTC_ZONE).format(TIME_FORMAT), moment.tz(UTC_ZONE).format(TIME_FORMAT))
}
