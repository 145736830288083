export enum SERVICE_CLASS {
    Sedan="business",
    SUV="van"
}

export function getAllServiceClasses(): SERVICE_CLASS[] {
    return [
        SERVICE_CLASS.Sedan,
        SERVICE_CLASS.SUV
    ]
}
