import http, {getToken} from "@/http-common";


export async function importPdf(file : File) {
    try {
        const formData = new FormData();
        formData.append('file', file);
        const headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': await getToken()
        };
        const res = await http.post("/pdf/import", formData, {headers});
        return res.data;
    } catch (err) {
        console.log("Error importing PDF");
    }
    return [];
}
