import axios from "axios";
import {getAuth0Instance} from "./auth0";
import {createConfig} from "@/config";

const config = await createConfig()
export async function getToken() {
    try {
        const accessToken = await getAuth0Instance().getAccessTokenSilently();
        return `Bearer ${accessToken}`;
    } catch (err: any) {
        if (err.error === "missing_refresh_token") {
            const currentLocation = window.location.hash.substring(1)
            await getAuth0Instance().loginWithRedirect({
                appState: { target: '/callback?redirectTo='+ currentLocation }
            })
            const accessToken = await getAuth0Instance().getAccessTokenSilently();
            return `Bearer ${accessToken}`;
        }
        throw err
    }
}

export default axios.create({
    baseURL: config.apiUrl,
    headers: {
        "Content-type": "application/json",
    }
});
