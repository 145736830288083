import {v4 as UUID_v4} from "uuid";

export class DropOffCondition {
    constructor(businessId: string, contains: string, doesNotContain: string) {
        this.businessId = businessId
        this.contains = contains
        this.doesNotContain = doesNotContain
    }

    businessId: string
    contains: string
    doesNotContain: string
}

export function getNewDropOffCondition() {
    return new DropOffCondition(UUID_v4(), "", "")
}
