import { InjectionKey } from 'vue'
import { createStore, useStore as baseUseStore, Store } from 'vuex'
import {Driver} from "@/services/DriverService";
import {ConditionSet} from "@/model/acceptRide/ConditionSet";
import {Notification} from "@/model/Notification";

// define your typings for the store state
export interface State {
    driver: Driver | undefined,
    conditionSet: ConditionSet | undefined,
    ride: any,
    notifications: Notification[]
}

// define injection key
export const key: InjectionKey<Store<State>> = Symbol()

export const store = createStore<State>({
    state: {
        driver: undefined,
        conditionSet: undefined,
        ride: null,
        notifications: []
    }
})

// define your own `useStore` composition function
export function useStore () {
    return baseUseStore(key)
}
