import http, {getToken} from "@/http-common";

export async function getAllRides() {
    try {
        const res = await http.get("/rides", {
            headers: {
                'Authorization': await getToken()
            }
        });
        return res.data;
    } catch (err) {
        console.log("Error loading rides");
    }
    return [];
}

export async function getMyRides() {
    try {
        const res = await http.get("/rides/my-rides", {
            headers: {
                'Authorization': await getToken()
            }
        });
        return res.data;
    } catch (err) {
        console.log("Error loading rides");
    }
    return [];
}

export async function getAllRidesByDriver(driverId: string) {
    try {
        const res = await http.get("/rides/driver/" + driverId, {
            headers: {
                'Authorization': await getToken()
            }
        });
        return res.data;
    } catch (err) {
        console.log("Error loading rides");
    }
    return [];
}

export async function getRide(businessId: string) {
    try {
        const res = await http.get("/rides/" + businessId, {
            headers: {
                'Authorization': await getToken()
            }
        });
        return res.data;
    } catch (err) {
        console.log("Error loading ride");
    }
    return {};
}

export async function createRide(data: any) : Promise<any> {
    try {
        const response = await http.post(`/rides`, data, {
            method: 'POST',
            headers: {
                'Authorization': await getToken()
            }
        })
        return await response;
    } catch (err) {
        console.log("Error saving ride");
    }
    return {};

}
