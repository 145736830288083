import http, {getToken} from "@/http-common";
import {Config, getConfig} from "@/config";
import {v4 as UUID_v4} from "uuid";

export class DriverAlternateName {
    constructor(businessId: string, name: string) {
        this.businessId = businessId
        this.name = name
    }
    businessId: string
    name: string
}

export class Driver {
    constructor(businessId: string, email: string, firstName: string, lastName: string, commissionPercentage: number, comapnyId: string, alternateNames: DriverAlternateName[]) {
        this.businessId = businessId
        this.email = email
        this.firstName = firstName
        this.lastName = lastName
        this.commissionPercentage = commissionPercentage
        this.companyId = comapnyId
        this.alternateNames = alternateNames
    }

    businessId: string
    email: string
    firstName: string
    lastName: string
    commissionPercentage: number
    companyId: string
    alternateNames: DriverAlternateName[]
}

export function getNewDriver(): Driver {
    const config:Config = getConfig();
    return new Driver(UUID_v4(), '', '', '', 0, config.companyId, [])
}

export async function getAllDrivers(): Promise<Array<Driver>> {
    try {
        const res = await http.get("/drivers", {
            headers: {
                'Authorization': await getToken()
            }
        });
        return res.data;
    } catch (err) {
        console.log("Error loading drivers");
    }
    return [];
}

export async function getMyProfile(): Promise<Driver | undefined> {
    try {
        const res = await http.get("/drivers/profile", {
            headers: {
                'Authorization': await getToken()
            }
        });
        return res.data;
    } catch (err) {
        console.log("Error loading driver");
    }
    return undefined;
}

export async function getDriver(businessId: string): Promise<Driver | undefined> {
    try {
        const res = await http.get("/drivers/" + businessId, {
            headers: {
                'Authorization': await getToken()
            }
        });
        return res.data;
    } catch (err) {
        console.log("Error loading driver");
    }
    return undefined;
}

export async function createDriver(driver: Driver): Promise<any> {
    try {
        const response = await http.post(`/drivers`, driver, {
            method: 'POST',
            headers: {
                'Authorization': await getToken()
            }
        })
        return await response;
    } catch (err) {
        console.log("Error creating driver");
    }
    return undefined;
}

export async function updateDriver(driver: Driver): Promise<any> {
    try {
        const response = await http.put(`/drivers`, driver, {
            method: 'POST',
            headers: {
                'Authorization': await getToken()
            }
        })
        return await response;
    } catch (err) {
        console.log("Error updating driver");
    }
    return undefined;
}
